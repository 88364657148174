#back
  position: fixed
  top: 0
  bottom: 0
  z-index: -1
  height: 100vh
  width: 100vw
  background: linear-gradient(45deg, #572A1B, #2B5F75, #1B813E)
  background-size: 300% 300%
  animation: bgcGradation 5s ease infinite
  canvas
    position: fixed
    top: 0
    bottom: 0
    width: 100vw
    height: 100vh
    // filter: blur(10px)
    animation: canvasOpacity 5s alternate infinite ease-in-out

@keyframes bgcGradation
  0%
    background-position: 0% 50%
  50%
    background-position: 100% 50%
  100%
    background-position: 0% 50%

@keyframes canvasOpacity
  0%
    opacity: .5
    // filter: blur(20px)
  50%
    opacity: .6
    // filter: blur(8px)
  100%
    opacity: .5
    // filter: blur(20px)
