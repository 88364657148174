$maxW: 1080px

.companyName
  +font-gothic-rounded
  letter-spacing: .1em

.bgWh
  background-color: #fff

.section
  padding: 50px 15px
  margin: 2px auto
  display: flex
  flex-direction: column
  align-items: center
  text-align: left
  &>*
    width: $maxW
    +max-PC
      width: 100%

.items
  display: flex
  justify-content: space-around
  +max-SP
    flex-direction: column
    justify-content: flex-start
  .item
    flex-basis: 0
    flex-grow: 1
  .separator
    flex-basis: 20px
