@import 'https://fonts.googleapis.com/earlyaccess/mplus1p.css'
@import 'https://fonts.googleapis.com/earlyaccess/roundedmplus1c.css'

=font-gothic
  font-family: 'Mplus 1p', 游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif
  font-weight: 300
  font-display: swap

=font-gothic-rounded
  font-family: 'Rounded Mplus 1c', 'ヒラギノ丸ゴ Pro W6','ヒラギノ丸ゴ Pro','Hiragino Maru Gothic Pro','ヒラギノ角ゴ Pro W7','Hiragino Kaku Gothic Pro','HG丸ｺﾞｼｯｸM-PRO','HGMaruGothicMPRO'
  font-display: swap

body
  +font-gothic

h1, h2
  +font-gothic-rounded

h2
  font-size: 2em
  margin-bottom: 20px

h3
  font-size: 1.2em
  font-weight: bold
  margin-bottom: 10px
  a
    font-weight: bold

a
  text-decoration: none
  color: #26f
  font-weight: 400
  transition: .5s
  &:hover
    text-decoration: underline

p
  line-height: 1.5em

b
  font-weight: bold

.d-n
  display: none
