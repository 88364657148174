@import 'reset'
@import 'base'
@import 'mixin'
@import 'components'
@import 'back'

#topview
  display: flex
  align-items: center
  justify-content: center
  height: 80vh
  +max-SP
    height: 100vw
  .text
    margin: 10px
    color: #fff
    text-shadow: 0 0 1em #777, 0 0 1em #777
    h1
      font-size: 4em
      +max-SP
        font-size: 3em
    p
      margin-top: 20px

nav
  display: flex
  justify-content: space-around
  position: sticky
  top: 0
  height: 3em
  background: linear-gradient(rgba(#000, .5), rgba(#000, .4))
  padding: 0 calc((100vw - 1080px)/2)
  +max-SP
    font-size: .8em
    top: calc(100vh - 1em * 2.8)
  a
    flex-grow: 1
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    transition: .2s
    color: #fff
    text-shadow: 0 0 .5em #777, 0 0 .5em #777
    &:hover
      color: #000
      background: linear-gradient(rgba(#fff, .4), rgba(#fff,.2))
      font-weight: normal
      text-shadow: 0 0 .3em #fff, 0 0 .3em #fff
      text-decoration: none

#news
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  min-height: 4em
  padding: 10px
  p
    line-height: 2

#greeting
  p
    column-count: 2
    +max-SP
      column-count: 1

#info
  .map
    +max-SP
      height: 40vh
  .grid
    display: grid
    grid-template-columns: auto 1fr
    grid-gap: 10px 20px
    margin-top: 20px
    .label
      justify-self: flex-end
    .enkaku
      grid-gap: 5px 10px
      margin-top: 0
      +max-SP
        display: flex
        flex-direction: column
        p:nth-child(2n)
          margin-bottom: 5px

#works
  .items:nth-of-type(n+2)
    margin-top: 20px

#contact
  margin: 20px auto 0
  color: #fff
  p
    margin-top: -5px
  .list
    // fn: contact list
    min-height: 40vh
    display: grid
    grid-template-columns: auto 1fr
    grid-template-rows: auto
    grid-gap: 40px
    +max-SP
      grid-template-columns: 100%
      grid-gap: 0
    p
      font-size: 2em
      align-self: center
      font-weight: bold
      +max-SP
        font-size: 1.2em
    .label
      justify-self: right
      font-weight: normal
      +max-SP
        justify-self: left
    .d-n
      display: none

  form
    margin: 30px auto
    display: grid
    grid-template-columns: auto 1fr
    grid-template-rows: 3em 3em 10em 3em
    grid-gap: 20px
    transition: 1s
    +max-SP
      grid-template-columns: 100%
      grid-template-rows: 1em 2em 1em 2em 1em 5em 0 6em
      justify-content: flex-start
      grid-gap: 20px 0 10px 0
    label
      justify-self: flex-end
      align-self: center
      +max-SP
        justify-self: flex-start
    p
      margin: auto 0
      font-weight: bold
    div
      display: flex
    input, textarea
      border: none
      border-radius: .5em
    input
      padding: 0 1em
      font-size: .8em
      width: calc(100% - 2em)
    textarea
      padding: 1em 1em
      font-size: .8em
      width: calc(100% - 2em)
    .buttonContainer
      display: flex
      flex-flow: row
      justify-content: space-between
      +max-SP
          flex-flow: column
      .button
        height: 100%
        width: calc(50% - 10px)
        +max-SP
          width: 100%
          height: 3em
    .mailBody
      overflow-y: scroll
  .callInfo
    font-size: 1.3em
  .sendSuccess
    @extend .callInfo
    margin: 20px 0

footer
  padding: 30px 0
  display: flex
  justify-content: center
  align-items: center
  @include font-gothic-rounded
  color: #fff
  background: linear-gradient(rgba(#000, .2), rgba(#000,.4))
  +max-SP
    padding-bottom: 3.8em
